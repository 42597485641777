<template>
  <Head>
    <title>Документы {{ $titleEnd }}</title>
    <meta
      name="description"
      content="Полный список документации жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА – бухгалтерский баланс, заключение экспертизы, проектная декларация и т.д."
    />
    <meta property="og:title" :content="'Документы ' + $titleEnd" />
    <meta
      property="og:description"
      content="Полный список документации жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА – бухгалтерский баланс, заключение экспертизы, проектная декларация и т.д."
    />
  </Head>
  <main class="main">
    <div class="container">
      <Breadcrumbs
        :links="[{ name: 'Home', text: 'Главная' }, { text: 'Документы' }]"
      />
      <h1 class="page-title h2">Документы</h1>
      <table v-if="docs.length > 0" class="list">
        <tr
          v-for="item in docs"
          :key="item.data.id"
          @click="open(item.data.source)"
        >
          <td class="list__icon pl-0">
            <div class="icon">
              <Icon icon="doc" />
            </div>
          </td>
          <td class="list__title">
            <a
              :href="item.data.source"
              target="_blank"
              @click.stop
              v-html="item.data.title"
            />
          </td>
          <td class="list__size">
            {{ humanFileSize(item.data.size) }}
          </td>
          <td class="list__date">
            Дата публикации: {{ item.data.date.split(" ")[0] }}
          </td>
        </tr>
      </table>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    Breadcrumbs,
  },
  data() {
    return {
      docs: [],
    };
  },
  async mounted() {
    try {
      const response = await axios.get(
        this.$endpoint + "publication/966?language=ru"
      );

      this.docs = response.data.data.content[0]?.body.blocks?.sort(
        (a, b) =>
          this.stringToDate(b.data.date) - this.stringToDate(a.data.date)
      );
    } catch (err) {
      this.$Progress.fail();
    } finally {
      this.$Progress.finish();
    }
  },
  methods: {
    humanFileSize(size) {
      var i = Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "kB", "MB", "GB", "TB"][i]
      );
    },
    stringToDate(dateString) {
      const stripedDateString = dateString.replace(/<[^>]*>?/gm, "");

      return new Date([
        stripedDateString.slice(0, 10).split(".").reverse(),
        stripedDateString.substring(11),
      ]);
    },
    open(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.list td {
  padding-top: 24px;
  padding-bottom: 24px;
  cursor: pointer;
}

.list tr:first-child td {
  padding-top: 0;
}

.list__title {
  font-weight: normal;
  font-size: 24px;
  line-height: 1.3;
  word-break: break-word;
}

.list__title a {
  color: inherit;
  text-decoration: none;
}

.list__size {
  white-space: nowrap;
}

.list__date {
  padding-right: 0;
  color: #9c9b9b;
  white-space: nowrap;
}

.list__icon .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  transition: border-color 0.1s, background 0.1s;
}

.list__icon .icon svg {
  transition: stroke 0.1s;
}

body.-notouch .list tr:hover .list__icon .icon {
  border-color: #205640;
  background: #205640;
}

body.-notouch .list tr:hover .list__icon .icon svg {
  stroke: #fff;
}

body.-notouch .list tr:hover .list__title a {
  text-decoration: underline;
}
@media (max-width: 1279px) {
  .list__title {
    padding-left: 0;
    font-size: 22px;
  }
}
@media (max-width: 991px) {
  .list {
    font-size: 16px;
  }

  .list__icon {
    display: none !important;
  }

  .list__title {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .list {
    display: block;
    font-weight: normal;
    font-size: 14px;
  }

  .list tr {
    display: block;
    padding: 20px 0;
    border-bottom: 1px solid #e5e5e5;
  }

  .list tr:first-child {
    padding-top: 0;
  }

  .list tr:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  .list td {
    display: block;
    padding: 0;
    border: 0;
  }

  .list__title {
    margin-bottom: 5px;
  }

  .list__size {
    margin-bottom: 2px;
  }
}
</style>
